<template>
  <div class="align-self-start blog-setting-container">
    <div class="news">
      <h5>
        <span class="strike-20">Najnowsze</span>
        <span class="strike-20">wpisy</span>
      </h5>
      <ul>
        <li v-for="item in firstFiveTopic" :key="item.id">
          <router-link :to="'/blog/' + item.id"> {{ item.title }}</router-link>
        </li>
      </ul>
    </div>
    <div class="archive">
      <h5><span class="strike-20">Archiwum</span></h5>
      <ul>
        <router-link
          v-for="month in months"
          :key="month.id"
          :to="'/blog?month=' + month.id"
          ><li>{{ month.name }}</li></router-link
        >
      </ul>
    </div>
  </div>
</template>

<script>
import Data from "../data/blog.data";
import Months from "../data/months.data";
export default {
  data: function() {
    return {
      firstFiveTopic: [],
      months: Months
    };
  },
  created() {
    this.firstFiveTopic = Data.slice(0, 5).sort(this.compare);
  },
  methods: {
    compare(a, b) {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    }
  }
};
</script>

<style scoped>
.blog-setting-container {
  margin: 35px 10px 10px 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.blog-setting-container ul {
  list-style: none;
}

.archive li {
  cursor: pointer;
}

.blogPosts:nth-child(1),
.blogPosts:nth-child(2) {
  padding-bottom: 10px;
  border-bottom: 2px solid grey;
}
li,
a {
  color: black;
}

li:hover {
  text-decoration: underline;
}
.blog-post-container {
  padding: 30px 10px;
}
</style>
